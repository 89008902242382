import { Button } from '@/components/Button';
import { Divider } from '@/components/Divider';
import { Form } from '@/components/Form';
import { Flex } from '@/components/Grid';
import { Heading } from '@/components/Heading';
import { Icon } from '@/components/Icon';
import { Input } from '@/components/Input';
import { Link } from '@/components/Link';
import { Section } from '@/components/Section';
import { SsoLoginButtonList } from '@/components/Sso';
import { Text } from '@/components/Text';
import { getAccount, login, useAccount } from '@/resource/account';
import { routeToPath } from '@/util/routeToPath';
import { staticProps } from '@/util/staticProps';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useBoolean } from 'usehooks-ts';
import z from 'zod';

const schema = z.object({
  email: z.string().email(),
  password: z.string().min(2),
});

export default function Login() {
  const router = useRouter();
  const { profile } = useAccount();
  const invalidGrant = useBoolean(false);
  const visibility = useBoolean(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const enabled = schema.safeParse({ email, password }).success;
  const loggedIn = profile != null;

  useEffect(() => {
    if (profile) {
      router.push(routeToPath({ type: 'account' }));
    }
  }, [profile, router]);

  return (
    <>
      <NextSeo title="Login" />

      <Section>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            login({ email, password })
              .then(() => getAccount())
              .catch((err) => {
                console.error(err);
                invalidGrant.setTrue();
              });
          }}
        >
          <Flex dir="column" gap={3}>
            <Heading align="center">Welkom terug!</Heading>

            <Input
              autoComplete="nope"
              label="E-mailadres"
              type="email"
              id="email"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
              disabled={loggedIn}
            />

            <Input
              autoComplete="new-password"
              label="Wachtwoord"
              type={visibility.value ? 'text' : 'password'}
              id="pw"
              value={password}
              required
              onChange={(e) => setPassword(e.target.value)}
              disabled={loggedIn}
              append={
                <Icon
                  icon={visibility.value ? 'eye_closed' : 'eye'}
                  onClick={() => visibility.toggle()}
                />
              }
            />

            {invalidGrant.value && (
              <Text align="center" theme="error" role="alert">
                Dit profiel kon niet gevonden worden.
              </Text>
            )}

            <Button
              align="center"
              type="submit"
              icon="avatar"
              disabled={loggedIn || !enabled}
            >
              Login
            </Button>

            <Text align="center">
              <Link underline to={{ type: 'forgot_password' }}>
                Wachtwoord vergeten
              </Link>
            </Text>

            <Divider>of</Divider>

            <SsoLoginButtonList />

            <Flex dir="column" gap={0}>
              <Text align="center">Heb je nog geen account?</Text>
              <Text align="center">
                <Link underline to={{ type: 'register' }}>
                  Account aanmaken
                </Link>
              </Text>
            </Flex>
          </Flex>
        </Form>
      </Section>
    </>
  );
}

export const getStaticProps = staticProps.resolve(async () => {
  return {
    props: {},
    revalidate: 10,
  };
});
