import { ReactNode } from 'react';
import styles from './Divider.module.scss';

type Props = {
  children: ReactNode;
};

export function Divider({ children }: Props) {
  return (
    <div className={styles.Divider}>
      <span></span>
      <span>{children}</span>
      <span></span>
    </div>
  );
}
